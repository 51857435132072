const Invoices = () => import( /* webpackChunkName: "Invoices" */ './pages/Invoices');
const ProFormaInvoices = () => import( /* webpackChunkName: "ProFormaInvoices" */ './pages/Invoices/ProFormaInvoices');


const pages = [
    {
      path: '/',
      redirect: '/invoices'
    },
    {
      path: '/invoices',
      name: 'Invoices',
      component: Invoices,
      children: [
        {
          path: '',
          redirect: '/invoices/pro-forma-invoices'
        },
        {
          path: 'pro-forma-invoices',
          name: 'ProFormaInvoices',
          component: ProFormaInvoices
        }
      ]
    }
];
    
export {
    pages
}