import { computed } from 'vue';
import { i18n } from './i18n';
const { t } = i18n.global;

const menuItems = computed(() => [{
            slug: 'invoices',
            path: '/invoices',
            name: 'Invoices',
            label: t('invoicehub.pages.menuItems.invoices.linkLabel'),
            children: [
                {
                    path: '/invoices/pro-forma-invoices',
                    name: 'ProFormaInvoices',
                    label: t('invoicehub.pages.menuItems.invoices.children.proFormaInvoices.linkLabel')
                }
            ]
        }]);

    
export {
    menuItems
}